import { reactive } from 'vue';

const tag = '?tag=wonderchoic02-20';
const rootTreeFile = 'bestsellers.json.tree.json';
const browseNodeEndpoint = 'bnodes/bnode_';
export default class Model {
  constructor() {
    this.data = null;
    this.loading = true;
    this.loadedCallbacks = [];
    fetch(rootTreeFile).then(x => x.json()).then(x => {
      this.data = x;
      this.loading = false;
      this.loadedCallbacks.forEach(callback => callback());
    });
  }

  onceLoaded(callback) {
    if (this.loading) {
      this.loadedCallbacks.push(callback);
    } else {
      setTimeout(callback, 0);
    }
  }

  pickRandom() {
    if (this.loading) {
      throw new Error('Cannot pick random while loading');
    }
    let result = [];
    pickToResult(this.data, result);
    return result;
  }
}

function pickToResult(data, result) {
  let array = data.c;
  if (!array) return result;

  shuffle(array);
  let item = array[0];
  const itemResult = reactive({
    name: item.n,
    url: 'https://www.amazon.com/' + item.l + tag,
    fetching: true,
    top: [],
    array,
    level: result.length,
    // only show the first item for now, and after animation ends - we will show the next level
    isVisible: result.length === 0,
    animationDone: false
  });

  fetchTop(itemResult, getBrowseNode(item.l))
  result.push(itemResult);

  // go deeper one level:
  return pickToResult(item, result);
}

function fetchTop(targetItem, browseNode) {
  fetch(browseNodeEndpoint + browseNode + '.json').then(x => x.json()).then(x => {
    const top = x.p;//.slice(0, 5);
    top.forEach(item => {
      item.url = 'https://www.amazon.com' + item.url + tag
    });
    targetItem.top = top;
    targetItem.fetching = false;
  }).catch(error => {
    targetItem.fetching = false;
    targetItem.error = true;
    targetItem.errorMessage = error.message;
  });
}

function getBrowseNode(url) {
  if (url.endsWith('/')) url = url.slice(0, -1);
  else throw new Error('Invalid browse node url: ' + url);
  return url.split('/').pop();
}

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const t = array[i];
    array[i] = array[j];
    array[j] = t;
  }
}