<template>
<div>
  <div :class="{ 'transparent': isAnimating, 'fade-in': !isAnimating && selected }">
    <h1>Discover new reading categories with Wonder Choice</h1>
    <h3>This website randomly picks a category from the list of best-selling books on Amazon.com</h3>
    <div>
      Simply <a href='#' @click.prevent='pickRandom'>click here</a> to generate a random category, then purchase and read a best-selling book from that category.
    </div>
  </div>
  <div v-if='selected' class='results'>
      <div v-for='item in visibleItems' :key="item.url" class='cell'>
        <wheel :item='item' :duration='2300' @done="$event => onAnimationDone(item)"></wheel>
        <div v-if='!item.fetching && treeReady' class='cell-content'>
          <div v-for='(top, index) in item.top' :key="index" class='cover'>
            <a :href='top.url' target='_blank' :title="getItemTitle(top)"><img :src='top.image'></a>
          </div>
          <div v-if="item.error" class="category-error">
            Sorry, we couldn't load best sellers for this category. 
            It may be due to a network error or a problem in our code. 
            <a :href="item.url" target="_blank">Click here</a> to go directly to the category on Amazon and see if there are any best sellers there
          </div>
        </div>
        <div v-if='item.fetching && treeReady'>Loading best sellers...</div>
      </div>
  </div>

  <div v-if='modelLoading'>
    Loading all categories...
  </div>
  <div v-if='!selected && !modelLoading'>
    <p>Why? </p>
    Reading books from diverse genres can broaden your perspective, deepen your empathy, and stimulate your imagination. Give it a try!
  </div>
</div>
</template>

<script>
import Wheel from './components/Wheel.vue';
import Model from './model.js'

let model = new Model();

export default {
  name: 'App',
  components: {
    Wheel
  },
  data() {
    return {
      selected: null,
      modelLoading: false,
      treeReady: false,
      isAnimating: false,
    }
  },
  computed: {
    visibleItems() {
      if (!this.selected) return []
      return this.selected.filter(item => item.isVisible);
    }
  },
  methods: {
    onAnimationDone(item) {
      item.animationDone = true;
      if (item.level < this.selected.length - 1) {
        this.selected[item.level + 1].isVisible = true;
      } else {
        this.treeReady = true;
        this.isAnimating = false;
        setInfoVisible(true);
      }
    },
    getItemTitle(item) {
      let title = [];
      if (item.title) {
        title.push(item.title);
      }
      // if (item.rating) {
      //   title.push('Rating: ' + item.rating);
      // }
      // if (item.reviewCount) {
      //   title.push('Reviews: ' + item.reviewCount);
      // }
      return title.join('\n');
    },
    pickRandom() {
      this.isAnimating = true;
      setInfoVisible(false);
      if (model.loading) {
        this.modelLoading = true;
        this.model.onceLoaded(() => {
          this.modelLoading = false;
          this.pickRandom();
        });
        return;
      }

      this.treeReady = false;
      this.selected = model.pickRandom();
    }
  }
}

function setInfoVisible(isVisible) {
  let info = document.querySelector('.info');
  if (!info) return;
  if (isVisible) {
    info.classList.remove('transparent');
    info.classList.add('fade-in');
  } else {
    info.classList.remove('fade-in');
    info.classList.add('transparent');
  }
}
</script>

<style>
.results {
  margin: 24px 8px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.results .cell {
  max-width: 200px;
  min-width: 100px;
}
.cell-header span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.animated-cell-header {
  position: relative;
  white-space: nowrap;
  align-items: center;
  display: flex;
  text-decoration: none;
  color: #2c3e50; 
}
.animated-cell-header span {
  left: 20px;
}

.cell-header {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #0A99AF;
  height: 42px;
  margin-left: 21px;
  padding-left: 8px;
  text-decoration: none;
  color: white;
  white-space: nowrap;
}

.cell-header:before {
  content: "";
  left: -20px;
  position: absolute;
  border-top: 21px solid #0A99AF;
  border-bottom: 21px solid #0A99AF;
  border-right: 1px solid #0A99AF;
  border-left: 21px solid transparent;
}
.cell-header:after {
  content: "";
  top: 0;
  right: -41px;
  position: absolute;
  border: 21px solid transparent;
  border-left: 21px solid #0A99AF;
}
.cell:not(:last-child) .cell-header .after {
    position: absolute;
    top: 0;
    right: -4px;
}
.cell:last-child .cell-header {
  margin-right: 21px;
}

.cell:last-child .cell-content {
  border-right: 1px solid transparent;
}

.cell-content {
  border-right: 1px solid #0A99AF;
  height: 100%;
}
.cover a {
  display: block;
}
.cover a img {
  width: 100%;
  padding: 4px;
  object-fit: scale-down;
  max-width: 200px;
}

.transparent {
  opacity: 0;
  pointer-events: none;
}

.fade-in {
  animation: fadeIn 1s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.category-error {
  font-size: 12px;
    font-family: monospace;
    color: orangered;
    padding: 4px;
}
</style>
