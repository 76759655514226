<template>
  <a :class="{'cell-header': animationDone, 'animated-cell-header': !animationDone }" ref="container" :href='item.url' target='_blank' :title='item.name'></a>
</template>

<script>
export default {
  name: 'Wheel',

  props: {
    item: {
      type: Object,
      required: true
    },
    duration: {
      type: Number,
      required: true
    }
  },

  mounted() {
    this.animateWheel();
  },

  data() {
    return {
      animationDone: false
    };
  },

  methods: {
    animateWheel() {
      const self = this;
      const container = this.$refs.container;
      let strings = this.item.array.map((item) => item.n);
      let anglePerString = 2 * Math.PI / strings.length;
      let textWithAngle = strings.map((text, i) => {
        return {
          angle: i * anglePerString,
          text,
          ui: null
        };
      });
      if (textWithAngle.length <= 1) {
        createUI(textWithAngle[0], container);
        markItemAsSelected(textWithAngle[0], self);
        return;
      }

      // given anglePerString, and the height of the string 20 px we want to find R such
      //  that strings do not overlap
      let R = 24 / Math.sin(anglePerString);

      let totalSpins = 2;
      let totalTimesCalled = this.duration / 16;
      let dt = 1 / totalTimesCalled;
      let currentT = 0;

      let currentAngle = 0;
      requestAnimationFrame(animate);


      function animate() {
        currentAngle = ease(currentT) * totalSpins * 2 * Math.PI;
        let visibleAngleRange = Math.PI / 3;

        textWithAngle.forEach((item) => {
          let angle = normalizeAngle(item.angle + currentAngle);
          let itemVisible =
            angle < visibleAngleRange || angle > 2 * Math.PI - visibleAngleRange;

          // items at `visibleRange` should have 0.2 opacity, and as angle approaches 0 degrees opacity should be 1:
          let opacity = 1;
          if (angle < visibleAngleRange) {
            opacity = 1 - angle / visibleAngleRange;
          } else if (angle > 2 * Math.PI - visibleAngleRange) {
            opacity = 1 - (2 * Math.PI - angle) / visibleAngleRange;
          }

          if (itemVisible && !item.ui) {
            createUI(item, container);
          } else if (!itemVisible && item.ui) {
            item.ui.remove();
            item.ui = null;
          }
          if (item.ui) {
            const y = R * Math.sin(angle);
            item.ui.style.top = y + 'px';
            item.ui.style.opacity = opacity;
          }
        });
        currentT += dt;
        if (currentT <= 1) requestAnimationFrame(animate);
        else {
          // we're done, let's find the item that's closest to the top
          for (let i = 1; i < textWithAngle.length; i++) {
            if (textWithAngle[i].ui) {
              textWithAngle[i].ui.remove();
              textWithAngle[i].ui = null;
            }
          }
          markItemAsSelected(textWithAngle[0], self);
        }
      }
    }
  }
};

function normalizeAngle(angle) {
  return angle % (2 * Math.PI);
}

function ease(t) {
  // we want to start fast and then slow down, so we use a quadratic function:
  return t * (2 - t);
}

function createUI(item, container) {
  item.ui = document.createElement('span');
  // item.ui.classList.add('cell-header');
  item.ui.textContent = item.text;
  item.ui.style.position = 'absolute';

  container.appendChild(item.ui);
}

function markItemAsSelected(item, self) {
  item.ui.style.top = '';
  item.ui.style.position = '';
  item.ui.style.opacity = '';
  self.$emit('done', item.text);
  self.animationDone = true;
}
</script>

<style>
</style>
